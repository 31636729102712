<!--
 * @Author: DY
 * @Date: 2020-08-03 16:02:26
 * @LastEditTime: 2022-09-07 15:32:26
 * @LastEditors: Please set LastEditors
 * @Description: 高级查询
 * @FilePath: \BusinessPlatform\src\views\tableList\queryCriteria.vue
-->
<template>
    <div class="advancedQuery el_left">
        <div class="criteria">
            <template v-for="(item, index) in queryCriteriaData">
                <dl
                    v-if="showComponents(item)"
                    :key="`queryCriteria_${index}`"
                    :class="{
                        'region': item.type == '',
                        'isMerge': item.isMerge == false
                    }"
                >
                    <dt class="componentName" :class="`componentName_${item.type}`">
                        {{ item.name }}:
                    </dt>
                    <dd class="omponentType" :style="{width: item.componentWidth}" :class="`componentType_${item.type}`">
                        <!-- 文本框 -->
                        <template v-if="item.type == 'text'">
                            <el-input
                                v-model="item.data"
                                :placeholder="item.placeholderText? `请输入${item.name}`:'请输入'"
                                @change="selectQueryCriteria(item.data, item)"
                            ></el-input>
                        </template>
                        <!-- 数字框 -->
                        <template v-if="item.type == 'numberText'">
                            <el-input-number
                                :controls="false"
                                v-model="item.data"
                                :min="item.minNumber"
                                :max="item.maxNumber"
                                :label="item.placeholderText? `请输入${item.name}`:'请输入'"
                                @change="selectQueryCriteria(item.data, item)"
                            ></el-input-number>
                        </template>
                        <!-- 下拉选择 -->
                        <template v-if="item.type == 'select'">
                            <el-select
                                clearable
                                collapse-tags
                                v-model="item.selectData"
                                :multiple="item.isMultiple"
                                :placeholder="item.placeholderText? `请选择${item.name}`:'请选择'"
                                @change="selectQueryCriteria(item.selectData, item)"
                            >
                                <template v-for="(k,i) in item.data">
                                    <el-option
                                        :key="i"
                                        :label="k[item.keyName]"
                                        :value="k[item.valueName]"
                                    >
                                    </el-option>
                                </template>
                            </el-select>
                        </template>
                        <!--
                        * @Description: 日期选择控件
                        * @parameter: dateType: week周，month月，year年
                        -->
                        <template v-if="item.type == 'dateControl'">
                            <el-date-picker
                                v-model="item.data"
                                :type="item.dateType"
                                placeholder="请选择"
                                :value-format="item.format?item.format:'yyyy-MM-dd'"
                                @change="selectQueryCriteria(item.data, item)"
                            >
                            </el-date-picker>
                        </template>
                        <!-- 年月日 日期段选择 -->
                        <template v-if="item.type == 'daterange'">
                            <el-date-picker
                                v-model="item.data"
                                type="daterange"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="selectQueryCriteria(item.data, item)"
                            >
                            </el-date-picker>
                        </template>
                        <!-- 年月日时分秒 时间段选择 -->
                        <template v-if="item.type == 'datePicker'">
                            <el-date-picker
                                v-model="item.data"
                                :type="item.dateType"
                                :format="item.format?item.format:'yyyy-MM-dd'"
                                :value-format="item.format?item.format:'yyyy-MM-dd'"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="selectQueryCriteria(item.data, item)"
                                :default-time="item.defaultTime"
                            >
                            </el-date-picker>
                        </template>
                    </dd>
                    <dd class="componentEndText" v-if="(item.endText || '') !==''">
                        {{ item.endText }}
                    </dd>
                </dl>
            </template>
            <button v-if="queryCriteria.config.isQueryBtn" @click="advancedQueryClick">
                查询
            </button>
        </div>
    </div>
</template>

<script>
export default {
    // 高级查询
    name: 'query-criteria',
    components: {},
    props: {
        queryCriteria: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            // 高级查询数据
            queryCriteriaData: [],
            // 高级查询多选选中数据
            checkList: [],
        };
    },
    computed: {},
    watch: {
        async queryCriteria(newVal) {
            if ((newVal.queryData || []).length > 0) {
                // 处理查询数据
                this.processingQueryData(newVal?.queryData);
            }
        },
    },
    created() {
        // 处理查询数据
        this.processingQueryData(this.queryCriteria?.queryData);
    },
    mounted() {
        const _that = this;
        document.onkeydown = function () {
            const key = window.event.keyCode;
            if (key === 13) {
                _that.$parent.list = [];
                _that.$parent.isLastPage = false;
                _that.advancedQueryData();
            }
        };
    },
    destroyed() {},
    methods: {
        // 处理查询数据
        async processingQueryData(data) {
            for (const item of data) {
                // 处理配置中默认数据
                if ((item.selectData || '') !== '') {
                    this.checkList.push({
                        id: item.id,
                        type: item.type,
                        data: item.selectData,
                    });
                } else if (['daterange', 'datePicker'].indexOf(item.type) > -1 && (item.data || []).length > 0) {
                    const defaultTime = [];
                    /** 
                    *  time_type_key=true, 时间段通过接口获取全局设置中的统计时间【按租户获取时间节点】
                    *  适用于时间段选择组件
                    *  默认添加, type=daterange不能修改
                    **/
                    if (item.time_type_key) {
                        const setTimeSlot = await this.getSetStatisticsTime(item.time_type_key);
                        const handleTime = [];
                        if (setTimeSlot && setTimeSlot.feature_value) {
                            const timeNode = JSON.parse(setTimeSlot.feature_value);
                            item.data.forEach((item, index) => {
                                let newTime = '';
                                if (index === 0) {
                                    newTime = item.split(' ')[0] + ' ' + timeNode.start_time;
                                } else if (index === 1) {
                                    newTime = item.split(' ')[0] + ' ' + timeNode.end_time;
                                }
                                handleTime.push(newTime);
                            });
                        } else {
                            item.data.forEach(item => {
                                handleTime.push(item);
                            });
                        }
                        item.data = handleTime;
                    }
                    item.data.forEach(item => {
                        defaultTime.push({ name: item });
                    });

                    this.checkList.push({
                        id: item.id,
                        type: item.type,
                        data: defaultTime,
                    });
                } else if (['dateControl'].indexOf(item.type) > -1 && item.data !== '') {
                    this.checkList.push({
                        id: item.id,
                        type: item.type,
                        data: [item.data],
                    });
                } else {
                    this.checkList.push({
                        id: item.id,
                        type: item.type,
                        data: [],
                    });
                }
                // isRequest=true, 数据需要通过接口请求获取
                if (item.isRequest && !item.isParent) {
                    await this.requestQueryData(item);
                }
                // isTokenGetValue=true, 选项需要从token字段中解析
                if (item.isTokenGetValue) {
                    await this.tokenGetValueData(item);
                }
                /** 
                 *  getSetTime=true, 时间段通过接口获取全局设置中的统计时间
                 *  只适用于type=datePicker的年月日时分秒 时间段选择组件
                 *  默认选项, 可以通过选择修改
                 *  defaultTimePeriod 后台配置统计时间段
                 *  getSetTime=true并且defaultTimePeriod有设置时间， defaultTimePeriod覆盖全局设置中的统计时间
                **/
                let setDefaultTime = [];
                if (item.getSetTimeSlot) {
                    const setTimeSlot = await this.getSetStatisticsTime(item.time_type_key);
                    if (setTimeSlot && setTimeSlot.feature_value) {
                        const timeNode = JSON.parse(setTimeSlot.feature_value);
                        setDefaultTime = [timeNode.start_time, timeNode.end_time];
                    } else {
                        setDefaultTime = ['00:00:00', '00:00:00'];
                    }
                }
                if ((item.defaultTimePeriod || '') !== '' ) {
                    setDefaultTime = item.defaultTimePeriod;
                }
                item.defaultTime = setDefaultTime;
            }
            this.queryCriteriaData = data;
            this.advancedQueryData();
        },

        // 列表高级查询条件选中
        selectQueryCriteria(data, obj) {
            const tagData = [];
            let newData = [];
            if (data) {
                newData = JSON.parse(JSON.stringify(data)).toString();
            }
            if (newData && typeof newData === 'string') {
                newData = newData.split(',');
            }
            if (['daterange', 'datePicker'].indexOf(obj.type) > -1) {
                if (newData) {
                    newData.forEach(item => {
                        tagData.push({
                            name: item,
                        });
                    });
                }
            } else if (['text', 'numberText', 'dateControl'].indexOf(obj.type) > -1) {
                tagData.push(...newData);
            } else if (['select'].indexOf(obj.type) > -1) {
                tagData.push(...newData);
            }
            this.checkList.forEach(k => {
                if (k.id === obj.id) {
                    if (tagData.length > 0 && tagData[0] !== '') {
                        k.data = tagData;
                    } else {
                        k.data = [];
                    }
                }
            });
            if (obj.isChildren && (obj.childrenName || '') !== '') {
                this.requestChildrenQueryData(obj);
            } else if (!this.queryCriteria.config.isQueryBtn) {
                this.advancedQueryData();
            }
        },

        // 组件显示隐藏
        showComponents(item) {
            let returnValue = true;
            if (item.type === 'select' && item.data.length === 0) {
                returnValue = false;
            }
            return returnValue;
        },

        // 请求子级查询条件
        requestChildrenQueryData(data) {
            const childrenData = this.queryCriteriaData.filter(k => k.id === data.childrenName);
            if (childrenData.length > 0) {
                childrenData[0].data = [];
                childrenData[0].selectData = '';
                this.checkList.forEach(k => {
                    if (k.id === childrenData[0].id) {
                        k.data = [];
                    }
                });
                if ((data.selectData || []).length > 0) {
                    this.requestQueryData(childrenData[0], data.selectData);
                } else if (childrenData[0].isChildren) {
                    this.requestChildrenQueryData(childrenData[0]);
                }
            }
        },

        // 请求查询数据
        async requestQueryData(item, code) {
            /**
             *data等于空，第一次初始化数据，
             *不清空selectData(配置的默认选项)
             **/
            if (item.data.length !== 0) {
                item.selectData = '';
            }
            let queryUrl = '';
            const fields = item.requestUrl.match(/{[^}{]*?}/g);
            if (fields) {
                fields.forEach(k => {
                    const fieldType = k.substring(1, k.length - 1).split('.');
                    if (fieldType[0] === 'token') {
                        const replaceField = this.$takeTokenParameters(fieldType[1]);
                        queryUrl = item.requestUrl.replace(k, replaceField);
                        item.requestUrl = queryUrl;
                    } else if (fieldType[0] === 'parent') {
                        queryUrl = item.requestUrl.replace(k, code);
                    }
                });
            } else {
                queryUrl = item.requestUrl;
            }
            queryUrl = `/interfaceApi/${item.requestPrefix}${queryUrl}`;
            const row = await this.getOptionData(queryUrl);
            if (row) {
                item.data = row;
            } else {
                item.data = [];
            }
            if (item.isChildren && (item.childrenName || '') !== '') {
                this.queryCriteriaData.forEach(k => {
                    if (item.childrenName === k.id) {
                        k.data = [];
                        k.selectData = '';
                    }
                });
                this.requestChildrenQueryData(item);
            }
            if (!this.queryCriteria.config.isQueryBtn) {
                this.advancedQueryData();
            }
        },

        // 高级查询数据
        advancedQueryData() {
            this.page = 0;
            this.$parent.requestData = {
                page: 0,
                code: this.$parent.requestData.code,
                limit: this.$parent.requestData.limit,
                cacheKey: this.$parent.requestData.cacheKey,
                customsort: this.$parent.requestData.customsort,
                filterFields: this.$parent.requestData.filterFields,
            };
            const tableQueryCriteria = [];
            this.checkList.forEach(item => {
                if (item.data === null || typeof item.data === 'undefined') {return;}
                if (['daterange', 'datePicker'].indexOf(item.type) > -1) {
                    if (item.data.length === 1) { // 单时间
                        tableQueryCriteria.push({
                            key: item.id,
                            value: item.data[0].name,
                        });
                    } else if (item.data.length === 2) { // 时间数组
                        tableQueryCriteria.push({
                            key: item.id + '_min',
                            value: item.data[0].name,
                        }, {
                            key: item.id + '_max',
                            value: item.data[1].name,
                        });
                    }
                } else {
                    let row = '';
                    if (['text', 'numberText', 'select', 'dateControl'].indexOf(item.type) > -1) {
                        row = item.data.toString();
                    }
                    if (row.length > 0) {
                        tableQueryCriteria.push({
                            key: item.id,
                            value: row,
                        });
                    }
                }
            });
            this.$emit('getTableList', tableQueryCriteria);
        },

        // 高级查询点击查询按钮
        advancedQueryClick() {
            this.$parent.list = [];
            this.$parent.isLastPage = false;
            this.advancedQueryData();
        },

        // 从token获取选项数据
        tokenGetValueData(item) {
            if (item.tokenKeyName && item.tokenValueName) {
                const option_name = this.$takeTokenParameters(item.tokenKeyName)?.split(',');
                const option_val = this.$takeTokenParameters(item.tokenValueName)?.split(',');
                if ((option_name || []).length > 0) {
                    option_name.forEach((k, i) => {
                        const new_option = [];
                        new_option[item.keyName] = k;
                        new_option[item.valueName] = option_val[i];
                        item.data.push(new_option);
                    });
                } else {
                    console.log(`token中${item.tokenKeyName}或${item.tokenValueName}为空`);
                }
            } else {
                console.log(`${item.name}没有配置${item.tokenKeyName}或${item.tokenValueName}`);
            }
        },

        // 获取全局设置中的统计时间
        getSetStatisticsTime(time_type_key) {
            const url = `/interfaceApi/baseinfo/features_manage/info/${time_type_key}`;
            return this.$axios.$get(url, { defEx: true });
        },


        // 拉取选项数据
        getOptionData(url) {
            return this.$axios.$get(url, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped></style>
